import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import * as PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Layout from '../components/Layouts/layout'
import Sidebar from '../components/Side-bar/side-bar'
import footerStyle from '../components/Footer/footer.module.css'
import SidebarFr from '../components/Side-bar/side-bar.fr'
import charts from '../components/Chart/chart.module.css'
import Lightbox from 'react-images'

const propTypes = {
  data: PropTypes.object.isRequired,
}
const FooterPadding = {
  marginTop: '115px',
}

class NatlAboutTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentImage: 0,
      lightBoxImages: [],
      lightboxIsOpen: false,
    }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
    /* console.log('opened', this.openLightbox) */
  }
  openLightboxImage(index, activeImages) {
    this.setState(
      {
        currentImage: index,
        lightboxIsOpen: true,
        lightBoxImages: activeImages,
      },
      () => {
        this.forceUpdate()
      }
    )
  }
  closeLightbox() {
    this.setState(
      {
        currentImage: 0,
        lightboxIsOpen: false,
      },
      () => {
        this.forceUpdate()
      }
    )
  }
  gotoPrevious() {
    this.setState(
      {
        currentImage: this.state.currentImage - 1,
      },
      () => {
        this.forceUpdate()
      }
    )
  }
  gotoNext() {
    this.setState(
      {
        currentImage: this.state.currentImage + 1,
      },
      () => {
        this.forceUpdate()
      }
    )
  }

  getYear() {
    return new Date().getFullYear()
  }

  render() {
    const { lightBoxImages } = this.state
    const natl = get(this, 'props.data.contentfulStatsPages')

    let chartEmpT =
      natl.sharedEmploymentTrends &&
        natl.sharedEmploymentTrends.employmentCharts
        ? natl.sharedEmploymentTrends.employmentCharts
        : null
    chartEmpT = chartEmpT
      ? Object.entries(chartEmpT).map(e => ({ image: e[1] }))
      : []

    let chartEmpTF =
      natl.sharedEmploymentTrends &&
        natl.sharedEmploymentTrends.employmentChartsFr
        ? natl.sharedEmploymentTrends.employmentChartsFr
        : null
    chartEmpTF = chartEmpTF
      ? Object.entries(chartEmpTF).map(e => ({ image: e[1] }))
      : []

    let chartIRC =
      natl.sharedInterestRateChanges &&
        natl.sharedInterestRateChanges.interestRateCharts
        ? natl.sharedInterestRateChanges.interestRateCharts
        : null
    chartIRC = chartIRC
      ? Object.entries(chartIRC).map(e => ({ image: e[1] }))
      : []

    let chartIRCF =
      natl.sharedInterestRateChanges &&
        natl.sharedInterestRateChanges.interestRateChartsFr
        ? natl.sharedInterestRateChanges.interestRateChartsFr
        : null
    chartIRCF = chartIRCF
      ? Object.entries(chartIRCF).map(e => ({ image: e[1] }))
      : []

    let chartCC =
      natl.sharedConsumerConfidence &&
        natl.sharedConsumerConfidence.consumerConfidenceCharts
        ? natl.sharedConsumerConfidence.consumerConfidenceCharts
        : null
    chartCC = chartCC ? Object.entries(chartCC).map(e => ({ image: e[1] })) : []

    let chartCCF =
      natl.sharedConsumerConfidence &&
        natl.sharedConsumerConfidence.consumerConfidenceChartsFr
        ? natl.sharedConsumerConfidence.consumerConfidenceChartsFr
        : null
    chartCCF = chartCCF
      ? Object.entries(chartCCF).map(e => ({ image: e[1] }))
      : []

    let chartMIG =
      natl.sharedMigration && natl.sharedMigration.migrationCharts
        ? natl.sharedMigration.migrationCharts
        : null
    chartMIG = chartMIG
      ? Object.entries(chartMIG).map(e => ({ image: e[1] }))
      : []

    let chartMIGF =
      natl.sharedMigration && natl.sharedMigration.migrationChartsFr
        ? natl.sharedMigration.migrationChartsFr
        : null
    chartMIGF = chartMIGF
      ? Object.entries(chartMIGF).map(e => ({ image: e[1] }))
      : []


    let chartMIGB =
      natl.sharedMigration && natl.sharedMigration.migrationChartsB
        ? natl.sharedMigration.migrationChartsB
        : null
    chartMIGB = chartMIGB
      ? Object.entries(chartMIGB).map(e => ({ image: e[1] }))
      : []


    let chartMIGC =
      natl.sharedMigration && natl.sharedMigration.migrationChartsC
        ? natl.sharedMigration.migrationChartsC
        : null
    chartMIGC = chartMIGC
      ? Object.entries(chartMIGC).map(e => ({ image: e[1] }))
      : []



    const { location } = this.props
    const { pathname } = location
    const lang = pathname.split('/')[1] == 'fr-CA' ? true : false

    const EmptChart = chartEmpT.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })
    const EmptChartF = chartEmpTF.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })

    const IRCChart = chartIRC.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })

    const IRCChartF = chartIRCF.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })
    console.log(IRCChartF, '====IRCChartF')

    const CCChart = chartCC.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })
    const CCChartF = chartCCF.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })

    const MigtChart = chartMIG.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })
    const MigtChartF = chartMIGF.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })

    const MigtChartB = chartMIGB.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })

    const MigtChartC = chartMIGB.map((item, id) => {
      return {
        src: item.image,
        width: 247,
        height: 169,
      }
    })






    return (
      <React.Fragment>
        <Layout
          data={this.props.data}
          location={this.props.location}
          noHeader="true"
        >
          {/* <Chart /> */}
          <div className="content-wrapper">
            <div className="row split">
              <div className="col-md">
                <div className="entry-header">
                  <h1
                    className="entry-title"
                    dangerouslySetInnerHTML={{
                      __html: natl.title,
                    }}
                  />
                </div>
                <div className="entry-content">
                  <div className="entry-sub">
                    {natl.sharedEmploymentTrends !== null && (
                      <h2
                        className="entry-headline"
                        dangerouslySetInnerHTML={{
                          __html: natl.sharedEmploymentTrends.sharedHeadline,
                        }}
                      />
                    )}

                    {natl.sharedInterestRateChanges !== null && (
                      <h2
                        className="entry-headline"
                        dangerouslySetInnerHTML={{
                          __html: natl.sharedInterestRateChanges.sharedHeadline,
                        }}
                      />
                    )}



                    {natl.sharedConsumerConfidence !== null && (
                      <h2
                        className="entry-headline"
                        dangerouslySetInnerHTML={{
                          __html: natl.sharedConsumerConfidence.sharedHeadline,
                        }}
                      />
                    )}
                    {natl.sharedMigration !== null && (
                      <h2
                        className="entry-headline"
                        dangerouslySetInnerHTML={{
                          __html: natl.sharedMigration.sharedHeadline,
                        }}
                      />
                    )}
                  </div>
                  <div className="mainFloatContainer">
                    <div className="LeftFloat">
                      <div>
                        <div className=".col-lg-">
                          {/* <!--  ==========================================================  -->
        <!--  START ---- EMPLOYMENT TRENDS CHART ==========================  -->
        <!--  ==========================================================  --> */}
                          {!lang
                            ? EmptChart.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, EmptChart)
                                  }
                                  key={index}
                                  alt="Employment Trends Charts"
                                  title="Employment Trends Charts"
                                />
                              </div>
                            ))
                            : EmptChartF.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, EmptChartF)
                                  }
                                  key={index}
                                  alt="Tendances emploi Graphique "
                                  title="Tendances emploi Graphique "
                                />
                              </div>
                            ))}

                          {/* <!--  ==========================================================  -->
        <!--  END ---- EMPLOYMENT TRENDS CHART==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!--  START ---- INTEREST RATETRENDS CHART==========================================================  --> */}
                          {!lang
                            ? IRCChart.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, IRCChart)
                                  }
                                  key={index}
                                  alt="Interest Rate Charts"
                                  title="Interest Rate Charts"
                                />
                              </div>
                            ))
                            : IRCChartF.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, IRCChartF)
                                  }
                                  key={index}
                                  alt="Taux d'intérêt Graphique "
                                  title="Taux d'intérêt Graphique "
                                />
                              </div>
                            ))}

                          {/* <!--  ==========================================================  -->
        <!--  END ---- RATE TRENDS CHART==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!--  START ---- CONSUMER CONFIDENCE CHART==========================================================  --> */}
                          {!lang
                            ? CCChart.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, CCChart)
                                  }
                                  key={index}
                                  alt="Consumer Confidence Charts"
                                  title="Consumer Confidence Charts"
                                />
                              </div>
                            ))
                            : CCChartF.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, CCChartF)
                                  }
                                  key={index}
                                  alt="Confiance des consommateurs Graphique"
                                  title="Confiance des consommateurs Graphique"
                                />
                              </div>
                            ))}

                          {/* <!--  ==========================================================  -->
        <!--  END ---- INTEREST CONSUMER CONFIDENCE CHART==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!--  START ---- MIGRATION CHART RENDER ==========================================================  --> */}
                          {!lang
                            ? MigtChart.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, MigtChart)
                                  }
                                  key={index}
                                  alt="Canada Migration Charts"
                                  title="Canada Migration Charts"
                                />
                              </div>
                            ))
                            : MigtChartF.map((value, index) => (
                              <div className={charts.tabContent}>
                                <img
                                  src={value.src}
                                  onClick={() =>
                                    this.openLightboxImage(index, MigtChartF)
                                  }
                                  key={index}
                                  alt="Migration au Canada Graphique"
                                  title="Migration au Canada Graphique"
                                />
                              </div>
                            ))}


                          {/* <!--  ==========================================================  -->
        <!--  END ---- MIGRATION CHART RENDER ==========================================================  --> */}
                        </div>
                      </div>
                    </div>

                    <div className="RightFloat">
                      {natl.sharedAbout !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedAbout.sharedContent.childMarkdownRemark
                                .html,
                          }}
                        />
                      )}
                      {natl.sharedEmploymentTrends !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedEmploymentTrends.sharedContent
                                .childContentfulRichText.html,
                          }}
                        />
                      )}

                      {/* <!--  ==========================================================  -->*/}
                      {natl.sharedConsumerConfidence !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedConsumerConfidence.sharedContent
                                .childContentfulRichText.html,
                          }}
                        />
                      )}
                      {natl.sharedInterestRateChanges !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedInterestRateChanges.sharedContent
                                .childContentfulRichText.html,
                          }}
                        />
                      )}
                      {natl.sharedMigration !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedMigration.sharedContent
                                .childContentfulRichText.html,
                          }}
                        />
                      )}
                    </div>
                  </div>


                  {/* <!--  ==========================================================  -->
        <!--  START ---- SECTION TWO ==========================  -->
        <!--  ==========================================================  --> */}

                  <div className="mainFloatContainer">
                    <div className="LeftFloat">
                      <div>
                        <div className=".col-lg-">
                          {/*         { MigtChartB.map((value, index) => (
                                <div className={charts.tabContent}>
                                  <img
                                    src={value.src}
                                    onClick={() =>
                                      this.openLightboxImage(index, MigtChartB)
                                    }
                                    key={index}
                                    alt="Canada Migration Charts"
                                    title="Canada Migration Charts"
                                  />
                                </div>
                              ))
                              } */}
                          {/* <!--  ==========================================================  -->
        <!--  END ---- MIGRATION CHART RENDER B==========================================================  --> */}
                        </div>
                      </div>
                    </div>
                    {/* <!--  ==========================================================  -->
        <!--  START ---- MIGRATION CONTENT RENDER C==========================================================  --> */}

                    <div className="RightFloat">
                      {/*    {natl.sharedMigration.sharedContentB !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedMigration.sharedContentB
                                .childContentfulRichText.html,
                          }}
                        />
                      )} */}

                    </div>
                    {/* <!--  ==========================================================  -->
        <!--  END ---- MIGRATION CONTENT RENDER C==========================================================  --> */}
                  </div>







                  {/* <!--  ==========================================================  -->
        <!--  START ---- SECTION THREE ==========================  -->
        <!--  ==========================================================  --> */}

                  <div className="mainFloatContainer">
                    <div className="LeftFloat">
                      <div>
                        <div className=".col-lg-">
                          {/*       { MigtChartC.map((value, index) => (
                                <div className={charts.tabContent}>
                                  <img
                                    src={value.src}
                                    onClick={() =>
                                      this.openLightboxImage(index, MigtChartC)
                                    }
                                    key={index}
                                    alt="Canada Migration Charts"
                                    title="Canada Migration Charts"
                                  />
                                </div>
                              ))
                              }  */}

                          {/* <!--  ==========================================================  -->
        <!--  END ---- MIGRATION CHART RENDER C==========================================================  --> */}
                        </div>
                      </div>
                    </div>
                    {/* <!--  ==========================================================  -->
        <!--  START ---- MIGRATION CONTENT RENDER C  ==========================================================  --> */}

                    <div className="RightFloat">
                      {/*       {natl.sharedMigration.sharedContentC !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html:
                              natl.sharedMigration.sharedContentC
                                .childContentfulRichText.html,
                          }}
                        />
                      )}   */}
                    </div>
                    {/* <!--  ==========================================================  -->
        <!--  END ---- MIGRATION CONTENT RENDER C  ==========================================================  --> */}
                  </div>




                  {/* <!--  ==========================================================  -->
  <!--  BOARD AND CREA DECLARATION HERE ==========================  -->
  <!--  ==========================================================  --> */}
                  <div
                    className={footerStyle.boardCredit}
                    style={FooterPadding}
                  >
                    <hr />
                    <p>
                      The information contained in this report has been prepared
                      by The Canadian Real Estate Association drawn from sources
                      deemed to be reliable, but the accuracy and completeness
                      of the information is not guaranteed. In providing this
                      information, The Canadian Real Estate Association does not
                      assume any responsibility or liability. Copyright ©
                      {this.getYear()} The Canadian Real Estate Association. All
                      rights reserved. Reproduction in whole or in part is
                      prohibited without written permission.
                    </p>
                  </div>
                  {/* <!--  ==========================================================  -->
  <!-- END EDITING PAGE CONTENT HERE =============================  -->
  <!--  ==========================================================  --></div> */}
                  <div className="chart_container">
                    {/* Char A Start */}
                    <div className="imageBox" />
                  </div>
                  {/* Char A End */}

                  <div className="chart_container">
                    <div className="imageBox" />
                  </div>
                </div>
              </div>
              <div className="col-sm- side-content mobile-style">
                <div className="chart">
                  {lang ? <SidebarFr /> : <Sidebar pathname={pathname} />}
                </div>
              </div>
            </div>
          </div>
        </Layout>

        <Lightbox
          images={lightBoxImages}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </React.Fragment>
    )
  }
}

NatlAboutTemplate.propTypes = propTypes

export default NatlAboutTemplate

export const pageQuery = graphql`
  query natlAboutQuery($id: String!) {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulStatsPages(id: { eq: $id }) {
      id
      contentful_id
      node_locale
      title
      contentA {
        childContentfulRichText {
          html
        }
      }
      sharedInterestRateChanges {
        title
        sharedHeadline
        interestRateChartsFr {
          image1
        }
        interestRateCharts {
          image1
        }
        sharedContent {
          childContentfulRichText {
            html
          }
        }
      }
      sharedAbout {
        title
        sharedHeadline
        sharedContent {
          childMarkdownRemark {
            html
          }
        }
      }
      sharedEmploymentTrends {
        title
        employmentChartsFr {
          image1
          image2
          image3
        }
        employmentCharts {
          image1
          image2
          image3
        }
        sharedHeadline
        sharedContent {
          childContentfulRichText {
            html
          }
        }
      }
      sharedConsumerConfidence {
        title
        sharedHeadline
        consumerConfidenceCharts {
          image1
        }
        consumerConfidenceChartsFr {
          image1
        }
        sharedContent {
          childContentfulRichText {
            html
          }
        }
        node_locale
      }
      sharedMigration {
        title
        sharedHeadline
        migrationCharts {
          image1
        }
        migrationChartsFr {
          image1
        }

        sharedContent {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`
